import auth from "@/router/middlewares/auth";

const routes = [

    {
        path: '/beneficiaries',
        name: 'beneficiaries.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Bénéficiaire", authRequired: true,
        },
        component: () => import('../views/beneficiaries/index.vue')
    },
    {
        path: '/beneficiaries/create',
        name: 'beneficiaries.create',
        meta: {
            middleware: [
                auth,
            ],
            title: "Nouveau bénéficiare", authRequired: true,
        },
        component: () => import('../views/beneficiaries/create.vue')
    },
    {
        path: '/beneficiaries/:id',
        name: 'beneficiaries.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Détails agent", authRequired: true,
        },
        component: () => import('../views/beneficiaries/show.vue')
    },
    {
        path: '/beneficiaries/:id/edit',
        name: 'beneficiaries.edit',
        meta: {
            middleware: [
                auth,
            ],
            title: "Bénéficiaire", authRequired: true,
        },
        component: () => import('../views/beneficiaries/create.vue')
    },
    {
        path: '/sites',
        name: 'sites.index',
        meta: {
            middleware: [
                auth,
            ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: "Sites", authRequired: true,
        },
        component: () => import('../views/sites/index.vue')
    },
    {
        path: '/sites/:id',
        name: 'sites.show',
        meta: {
            middleware: [
                auth,
            ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: "Détails du site", authRequired: true,
        },
        component: () => import('../views/sites/show.vue')
    },
    {
        path: '/companies',
        name: 'companies.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Entreprises", authRequired: true,
        },
        component: () => import('../views/companies/index.vue')
    },
    {
        path: '/partners',
        name: 'partners.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Partenaire", authRequired: true,
        },
        component: () => import('../views/partners/index.vue')
    },
    {
        path: '/infrastructures',
        name: 'infrastructures.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Infrastructures", authRequired: true,
        },
        component: () => import('../views/infras/index.vue')
    },
    {
        path: '/infrastructures/create',
        name: 'infrastructures.create',
        meta: {
            middleware: [
                auth,
            ],
            title: "Infrastructures", authRequired: true,
        },
        component: () => import('../views/infras/create.vue')
    },
    {
        path: '/infrastructures/:id/edit',
        name: 'infrastructures.edit',
        meta: {
            middleware: [
                auth,
            ],
            title: "Infrastructures", authRequired: true,
        },
        component: () => import('../views/infras/create.vue')
    },
    {
        path: '/infrastructures/:id',
        name: 'infrastructures.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Infrastructures", authRequired: true,
        },
        component: () => import('../views/infras/show.vue')
    },
    {
        path: '/fittings',
        name: 'fittings.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Aménagements", authRequired: true,
        },
        component: () => import('../views/fittings/index.vue')
    },
    {
        path: '/followups',
        name: 'followups.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Suivis", authRequired: true,
        },
        component: () => import('../views/followups/index.vue')
    },
    {
        path: '/conventions-report',
        name: 'conventions.report',
        meta: {
            middleware: [
                auth,
            ],
            title: "Rapport conventions", authRequired: true,
        },
        component: () => import('../views/conventions/index.vue')
    },
    {
        path: '/conventions',
        name: 'conventions.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Conventions", authRequired: true,
        },
        component: () => import('../views/conventions/index.vue')
    },
    {
        path: '/conventions/:id/edit',
        name: 'conventions.edit',
        meta: {
            middleware: [
                auth,
            ],
            title: "Conventions", authRequired: true,
        },
        component: () => import('../views/conventions/create.vue')
    },
    {
        path: '/conventions/create',
        name: 'conventions.create',
        meta: {
            middleware: [
                auth,
            ],
            title: "Conventions", authRequired: true,
        },
        component: () => import('../views/conventions/create.vue')
    },
    {
        path: '/conventions/:id',
        name: 'conventions.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Conventions", authRequired: true,
        },
        component: () => import('../views/conventions/show.vue')
    },
    {
        path: '/planifications',
        name: 'planifications.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Planification", authRequired: true,
        },
        component: () => import('../views/planifications/index.vue')
    },
    {
        path: '/disbursements',
        name: 'disbursements.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Décaissement", authRequired: true,
        },
        component: () => import('../views/disbursements/index.vue')
    },
    {
        path: '/disbursements/:id',
        name: 'disbursements.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Détails Décaissement", authRequired: true,
        },
        component: () => import('../views/disbursements/show.vue')
    },
    {
        path: '/gestion-execution-physique',
        name: 'manage.ph.exec',
        meta: {
            middleware: [
                auth,
            ],
            title: "Programmation activités", authRequired: true,
        },
        component: () => import('../views/conventions/index.vue')
    },
    {
        path: '/suivi-execution-physique',
        name: 'report.ph.exec',
        meta: {
            middleware: [
                auth,
            ],
            title: "Suivi activités", authRequired: true,
        },
        component: () => import('../views/conventions/index.vue')
    },
    {
        path: '/gestion-execution-financiere',
        name: 'manage.fin.exec',
        meta: {
            middleware: [
                auth,
            ],
            title: "Gestion exécution financiere", authRequired: true,
        },
        component: () => import('../views/disbursements/index.vue')
    },
    {
        path: '/report-execution-financiere',
        name: 'report.fin.exec',
        meta: {
            middleware: [
                auth,
            ],
            title: "Suivi exécution financiere", authRequired: true,
        },
        component: () => import('../views/disbursements/index.vue')
    },
    {
        path: '/gestion-indicateurs',
        name: 'manage.indic',
        meta: {
            middleware: [
                auth,
            ],
            title: "Cadre logique", authRequired: true,
        },
        component: () => import('../views/conventions/index.vue')
    },
    {
        path: '/suivi-indicateurs',
        name: 'report.indic',
        meta: {
            middleware: [
                auth,
            ],
            title: "Suivi indicateur", authRequired: true,
        },
        component: () => import('../views/conventions/index.vue')
    },

];


export default routes;