import moment from "moment/moment";

export const AppMixins =  {
    methods: {
        async downloadDataUrlFromJavascript(filename, url) {
            // Construct the 'a' element
            var link = document.createElement("a");
            link.download = filename;
            link.target = "_blank";

            // Construct the URI
            link.href = url;
            document.body.appendChild(link);
            link.click();

            // Cleanup the DOM
            document.body.removeChild(link);
            //delete link;

            /*const data = await fetch(url)
            const blob = await data.blob()
            const objectUrl = URL.createObjectURL(blob)

            const link = document.createElement('a')

            link.setAttribute('href', objectUrl)
            link.setAttribute('download', filename)
            link.style.display = 'none'

            document.body.appendChild(link)

            link.click()

            document.body.removeChild(link)*/
        },
        projectIsSelected()
        {
            return localStorage.getItem("project")
        },
        selectProject(project = undefined) {
            this.showProgressDialog(3000, "Rédirection")

            this.$store.dispatch('staff/accessProject', project?.id)
                .then(({data}) => {
                    this.accessing = false;
                    this.setSelectedProject(data.project)
                }).catch(() => {

            })
        },
        setSelectedProject: function (project) {
            if (project) {
                localStorage.setItem("project", project.code)
                this.currentProject = project.code
                window.location = '/';
            } else {
                localStorage.removeItem('project');
                window.location = '/onboarding'
            }
        },
        showProgressDialog(timer = 3000, title = "Progression",) {
            this.$swal.fire({
                title: title,
                timer: timer,
                text: 'Veuillez patienter, vous serez rédirigé dans quelques instants',
                allowOutsideClick: false,
                //timerProgressBar: true,
                didOpen: () => {
                    this.$swal.showLoading()
                },
            })
        },
        toast(type,message)
        {
            let vueBOx=this,
                Toast= this.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', vueBOx.$swal.stopTimer)
                        toast.addEventListener('mouseleave', vueBOx.$swal.resumeTimer)
                    }
                });
            Toast.fire({
                icon: type,
                title: message
            })
        },
        momentJs(data)
        {
            var date = new Date((data));
            return moment(date).startOf('hour').fromNow();
        },
        limitText: function (text,length)
        {
            if(text) {
                let mytext = text.slice(0, length);
                (text.length > 12) ? mytext = mytext + ' ...' : '';
                return mytext;
            }else{
                return '...';
            }
        },
        resolve(path, obj) {
            return path.split('.').reduce(function(prev, curr) {
                return prev ? prev[curr] : null
            }, obj || self)
        },

        userHasPermission(permission)
        {
            let userData = this.$store.getters["auth/user"]
            if (userData)
            {
                return this.$store.getters["auth/user"].permissions.includes(permission)
            }

            return false;
        },
        getConnected()
        {
            if (this.$store.getters["auth/user"])
                return this.$store.getters["auth/user"].data.identity;
        },
        isConnectedPartner(partnerId)
        {
            if (this.$store.getters["auth/user"]){
                return this.$store.getters["auth/user"].data.staff.partner_id === partnerId;
            }

            return false
        },
        getConnectedPartner()
        {
            if (this.$store.getters["auth/user"]){
                return this.$store.getters["auth/user"].data.staff.partner;
            }
            return null
        },
        getUserFullRole()
        {
            if (this.$store.getters["auth/user"])
                return this.$store.getters["auth/user"].data.roles[0]
        },
        getUserRole()
        {
            if (this.$store.getters["auth/user"])
                return this.$store.getters["auth/user"].data.roles[0].name
        },

    }
}